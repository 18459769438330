import classNames from 'clsx'
import Heading from '~/core/ui/Heading'
import { cn } from '../generic/shadcn-utils'
import { ChevronRightIcon } from 'lucide-react'

type PageTitle = string | React.ReactNode | React.ReactNode[]

export function Page(
  props: React.PropsWithChildren<{
    className?: string
    sidebar?: React.ReactNode
    contentContainerClassName?: string
    onScroll?: (el: HTMLDivElement) => void
  }>,
) {
  return (
    <div className={props.className}>
      <div className="hidden lg:block">{props.sidebar}</div>

      <div
        onScroll={e => props?.onScroll?.(e.currentTarget)}
        className={classNames(
          props.contentContainerClassName,
          `mx-auto flex flex-col h-full min-h-screen overflow-y-auto`,
        )}
      >
        {props.children}
      </div>
    </div>
  )
}

export function PageBody(
  props: React.PropsWithChildren<{
    className?: string
  }>,
) {
  const className = classNames(
    'w-full px-container flex flex-col flex-1',
    props.className,
  )

  return <div className={className}>{props.children}</div>
}
function PageHeaderTitle({ title }: { title: PageTitle }) {
  const titlesToRender = Array.isArray(title) ? title : [title]
  return (
    <div className="flex items-center">
      {titlesToRender.map((titleElement, idx) => (
        <Heading type={3} key={idx} className={cn(idx && 'pl-[15px]')}>
          <span className="flex items-center">
            <span className="font-semibold dark:text-white">
              {titleElement}
            </span>
            {idx + 1 < titlesToRender.length && (
              <ChevronRightIcon className=" ml-[15px] text-[#6D6D6D] size-[25px]" />
            )}
          </span>
        </Heading>
      ))}
    </div>
  )
}
export function PageHeader({
  children,
  title,
  description,
  mobileNavigation,
}: React.PropsWithChildren<{
  title: PageTitle
  description?: string | React.ReactNode
  mobileNavigation?: React.ReactNode
}>) {
  return (
    <div className="p-container flex items-start justify-between">
      <div
        className={
          'flex space-x-2 items-center lg:items-start lg:flex-col'
          + ' lg:space-x-0'
        }
      >
        <div className="flex items-center lg:hidden">{mobileNavigation}</div>
        <PageHeaderTitle title={title} />

        <Heading type={5} className="hidden lg:block">
          <span className="font-normal text-gray-600 dark:text-gray-400">
            {description}
          </span>
        </Heading>
      </div>

      {children}
    </div>
  )
}
